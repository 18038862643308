import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en";

const MissingKeyExtractor = {
  type: "logger",
  missingKeys: {},
  log: function log(args) {
    if (args.includes("i18next::translator: missingKey")) {
      if (!Object.keys(this.missingKeys).includes(args[4])) {
        Object.assign(this.missingKeys, { [args[4]]: args[4] });
      }
      console.log(this.missingKeys);
    } else {
      this.output("log", args);
    }
  },
  warn: function warn(args) {
    this.output("warn", args);
  },
  error: function error(args) {
    this.output("error", args);
  },
  output: function output(type, args) {
    if (console && console[type]) console[type].apply(console, args);
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(MissingKeyExtractor)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
    },
  });

export default i18n;
