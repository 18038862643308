import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./amplify";
import "./languages";
import Navigation from "./Navigation";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store";
import "./styles/index.scss";

const helmetContext = {};

// INJECTED

const errorFunction = async (error, query) => {
  if (error === "Unauthorized") {
    queryClient.clear();
    // await removePushToken();
    // store.dispatch(setIsLoggedIn(false));
  }

  console.error("[NETWORK ERROR WITHOUT EXISTING CACHE]: ", error.errors);
  if (query.state.data !== undefined) {
    // only show error if we already have data in the cache which indicates a failed background update
    console.error("[NETWORK ERROR WITH EXISTING CACHE]: ", error.errors);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
    },
  },
  mutationCache: new MutationCache({
    onError: errorFunction,
  }),
  queryCache: new QueryCache({
    onError: errorFunction,
  }),
});

// INJECTED

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <HelmetProvider context={helmetContext}>
            <Toaster
              toastOptions={{
                duration: 3000,
              }}
            />
            <Navigation />
          </HelmetProvider>
        </Router>
      </PersistGate>
    </QueryClientProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
