import classnames from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./genericregistered.module.scss";

export default function GenericRegistered() {
  return (
    <div className={styles.container}>
      <SkeletonTheme baseColor="#e6e6ed">
        <div className={styles.headlineTop}>
          <Skeleton
            containerClassName={classnames(styles.breadcrumbs, "mb-300")}
            count={3}
            height={20}
          />

          <div className={styles.user}>
            <Skeleton className="mr-50" circle width={48} height={48} />
            <p>
              <Skeleton count={2} width={150} />
            </p>
          </div>
        </div>

        <Skeleton className={classnames(styles.title, "mt-100 mb-100")} />

        <p className={styles.description}>
          <Skeleton count={2} />
        </p>

        {/* <p className={classnames("mt-300 mb-100")}>
          <Skeleton width={300} count={1} />
          <Skeleton width={450} count={2} />
        </p>
        <Skeleton
          containerClassName="tc-flex-a-center"
          className="mr-100"
          height={240}
          width={470}
          count={2}
        /> */}
      </SkeletonTheme>
    </div>
  );
}
