import gql from "graphql-tag";
import { useQuery } from "react-query";
import doRequest from "../utils/doRequest";

const listChannels = gql`
  query listChannels {
    listChannels {
      id
      name
      lastLogin
      logoUrl
    }
  }
`;

export function useListChannels() {
  return useQuery(
    "listChannels",
    async () =>
      doRequest(
        {
          query: listChannels,
        },
        "data.listChannels"
      ),
    { refetchOnWindowFocus: false }
  );
}
