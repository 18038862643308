import { Content } from "@carbon/react";
import React, { lazy, Suspense, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useListChannels } from "../../../api/channels/listChannels";
import GenericRegistered from "../../_skeletons/GenericRegistered";
import { withAuth } from "../components/withAuth";
import styles from "./registered.module.scss";

const Sidebar = lazy(() => import("../components/Sidebar"));
const Header = lazy(() => import("../components/Header"));

function Registered() {
  const dispatch = useDispatch();
  const sidebarLocked = useSelector(
    (state) => state.sidebarLocked,
    shallowEqual
  );
  const { data: channels } = useListChannels();
  const selectedCompany = useSelector(
    (state) => state.selectedCompany,
    shallowEqual
  );

  useEffect(() => {
    if (channels) {
      dispatch({
        type: "SET_COMPANIES",
        payload: channels,
      });
      if (!selectedCompany) {
        dispatch({
          type: "SELECT_COMPANY",
          payload: channels[0].id,
        });
      }
    }
  }, [channels]);

  const toggle = React.useCallback(() => {
    dispatch({
      type: "SIDEBAR_TOGGLE",
      payload: !sidebarLocked,
    });
  }, [sidebarLocked, dispatch]);

  return (
    <>
      <Suspense fallback={null}>
        <Header expanded={sidebarLocked} setExpanded={toggle} />
        <Sidebar expanded={sidebarLocked} setExpanded={toggle} />
      </Suspense>

      <Content
        className={
          styles.content + (sidebarLocked ? " " + styles.contentLock : "")
        }
      >
        <React.Suspense fallback={<GenericRegistered />}>
          <Outlet />
        </React.Suspense>
      </Content>
    </>
  );
}

export default withAuth(Registered);
