import { useQuery } from "react-query";
import gql from "graphql-tag";
import doRequest from "../utils/doRequest";
import store from "../../store";

const getUnreads = gql`
  query GetUnreads {
    countUnreadNotifications {
      unreadMessages
      unreadNotifications
    }
  }
`;

export function useGetUnreads() {
  return useQuery(
    "getUnreads",
    async () => {
      return doRequest(
        {
          query: getUnreads,
        },
        "data.countUnreadNotifications"
      );
    },
    {
      retry: 1,
      onError: (e) => {
        if (
          e.errors.some(
            (err) => err.message === "Request failed with status code 401"
          )
        ) {
          store.dispatch({
            type: "LOGOUT",
          });
        }
      },
    }
  );
}
