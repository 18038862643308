import { Suspense as ReactSuspense } from "react";
import { Outlet } from "react-router-dom";
import Loading from "../../Loading";

export default function Suspense() {
  return (
    <ReactSuspense fallback={<Loading />}>
      <Outlet />
    </ReactSuspense>
  );
}
