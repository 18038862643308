import { API } from "aws-amplify";
import { Buffer } from "buffer";
import store from "../../store";

const TWIIK_CLIENT_ID = process.env.REACT_APP_TWIIK_CLIENT_ID;
const TWIIK_CLIENT_SECRET = process.env.REACT_APP_TWIIK_CLIENT_SECRET;

const getPropByPath = (object, path, defaultValue) => {
  const _path = Array.isArray(path) ? path : path.split(".");
  if (object && _path.length) {
    return getPropByPath(object[_path.shift()], _path, defaultValue);
  }
  return object === undefined ? defaultValue : object;
};

export default async function doRequest(
  requestMeta,
  successPath,
  useBasicAuth = false
) {
  let result;
  if (useBasicAuth) {
    const apiString = `${TWIIK_CLIENT_ID}:${TWIIK_CLIENT_SECRET}`;
    result = await API.graphql(requestMeta, {
      Authorization: `BASIC ${Buffer.from(apiString, "utf-8").toString(
        "base64"
      )}`,
    });
  } else {
    const token = store.getState().auth.accessToken;
    result = await API.graphql(requestMeta, {
      Authorization: `BEARER ${token}`,
    });
  }
  if (successPath) {
    const data = getPropByPath(result, successPath);
    if (data.errorMessage) {
      throw data.errorMessage;
    }
    return data;
  } else if (result.errorMessage) {
    throw result.errorMessage;
  }
  return result;
}
