import { Amplify } from "aws-amplify";

// Amplify.configure({
//   aws_appsync_graphqlEndpoint:
//     // "https://fu4iok2xbvejpfsxvbnpvzkyp4.appsync-api.eu-north-1.amazonaws.com/graphql", // Aleckan
//     "https://vnuepqsyk5cfhd4hniv2ys3mri.appsync-api.eu-north-1.amazonaws.com/graphql", // CURRENT STAGE
//   // 'https://hnonsrayf5gupl6zd5gbsxloke.appsync-api.eu-north-1.amazonaws.com/graphql', // STSV
//   // 'https://ar5n3g3hmfeczoccktk46gj3mq.appsync-api.eu-north-1.amazonaws.com/graphql', // Prod
//   aws_appsync_region: "eu-north-1",
//   aws_appsync_authenticationType: "AWS_LAMBDA",
//   //aws_appsync_apiKey: "da2-ztckvl46crdsdgpjmobru5k3jy",
//   aws_appsync_apiKey: "da2-nlqrap4i2bfnxhchgspgph5epq", // Current STAGE
//   // aws_appsync_apiKey: 'da2-ntrq6esf2bhgxpuptg5vlg4ucm', // STSV
//   //aws_appsync_apiKey: 'da2-mnrfzbshpngfhfa3xlgy4wnjha', // Prod
// });

const huseyn = {
  aws_appsync_graphqlEndpoint:
    "https://wept7x6yqjemlhjendvsptekta.appsync-api.eu-north-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-north-1",
  aws_appsync_authenticationType: "AWS_LAMBDA",
  aws_appsync_apiKey: "da2-kw24eva76nhjllnamx6gfisuae",
};

const stage = {
  aws_appsync_graphqlEndpoint:
    "https://vnuepqsyk5cfhd4hniv2ys3mri.appsync-api.eu-north-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-north-1",
  aws_appsync_authenticationType: "AWS_LAMBDA",
  aws_appsync_apiKey: "da2-nlqrap4i2bfnxhchgspgph5epq",
};

Amplify.configure(huseyn);
