const generic = {
  "Twiik Coach": "Twiik Coach",
  "Coaching - Twiik Coach": "Coaching - Twiik Coach",
  "x - Twiik Coach": "{{item}}  - Twiik Coach",

  Coaches: "Coaches",
  Dashboard: "Dashboard",
  "PT Online & Bootcamps": "PT Online & Bootcamps",
  Search: "Search",
  "Get help": "Get help",
  "Learn how to use": "Learn how to use",
  "Central library": "Central library",
  "Campaign page": "Campaign page",
  "Page Not Found": "Page Not Found!",
  "The page you are looking for could not be found":
    "The page you are looking for could not be found.",
  soon: "soon",
  "Filter by x": "Filter by {{item}}",
  register: "Register",
  "Go to Homepage": "Go to Homepage",
  login: "Login",
  reset: "Password reset",
  Finances: "Finances",
  Settings: "Settings",
  Inactive: "Inactive",
  "View all": "View all",
  completed: "completed",
  Users: "Users",
  User: "User",
  Sent: "Sent",
  Price: "Price",
  Start: "Start",
  "See all help guides": "See all help guides",
  Product: "Product",
  Greetings: "Instructions",
  "Suggested guides": "Suggested guides",
  "Read more": "Read more",
  Play: "Play",
  "Yearly income": "Yearly income",
  "Loading...": "Loading...",
  "Next invoice": "Next invoice",
  Invoice: "Invoice",
  "Add bank details": "Add bank details",
  "Total months": "Total months",
  Messages: "Messages",
  Notifications: "Notifications",
  Leads: "Leads",
  "Quick actions": "Quick actions",
  Open: "Open",
  Profile: "Profile",
  Menu: "Menu",
  "Help me get started": "Help me get started",
  Guide: "Guide",
  "Get started": "Get started",
  "Side navigation": "Side navigation",
  "My profile": "My profile",
  Maximize: "Maximize",
  Name: "Name",
  Status: "Status",
  Company: "Company",
  Duration: "Duration",
  "Search PT-package": "Search PT-package",
  Completed: "Completed",
  Activate: "Activate",
  "x users": "{{users}} users",
  "x users (max x)": "{{users}} users (max {{max}})",
  "x new users": "{{user}} new user",
  "Invite user": "Invite user",
  Overview: "Overview",
  Coaching: "Coaching",
  Library: "Library",
  Channel: "Channel",
  Admin: "Admin",
  Customers: "Customers",
  "Client list": "Client list",
  "Customer stream": "Customer stream",
  Invites: "Invites",
  Notes: "Notes",
  Groups: "Groups",
  Plans: "Plans",
  "Workout templates": "Workout templates",
  "Text templates": "Text templates",
  "Task templates": "Task templates",
  Tests: "Tests",
  Exercises: "Exercises",
  Activities: "Activities",
  Media: "Media",
  "PT-Online": "PT-Online",
  Builder: "Builder",
  "Create Boot Camps": "Create Boot Camps",
  "Create Program products": "Create Program products",
  "Create Workout products": "Create Workout products",
  "Create Knowledge products": "Create Knowledge products",
  Purchases: "Purchases",
  Subscription: "Subscription",
  "Discount codes": "Discount codes",
  Testimonials: "Testimonials",
  "Channel news": "Channel news",
  "Company packages": "Company packages",
  Surveys: "Surveys",
  "Corporate challanges": "Corporate challanges",
  "Central exercise library": "Central exercise library",
  "Questions & test items": "Questions & test items",
  Economy: "Economy",
  coaching: "coaching",
  "More info about PT-Online": "More info about PT-Online",
  "You have x new leads from your campaign pages":
    "You have <0>{{number}} new leads</0> from your campaign pages",
  "What are leads": "What are leads?",
  Active: "Active",
  Archive: "Archive",
  "Create new item": "Create a new {{item}}",
  "No item created yet": "No {{item}}s created yet",
  Delete: "Delete",
  "Get started with": "Get started with {{item}}",
  Bootcamps: "Bootcamps",
  "Invite a customer": "Invite a customer",
  "Search x": "Search {{item}}",
  "Send greeting": "Send instruction",
  "Nothing has been found": "Nothing has been found",
  "My sales": "My sales",
  Guides: "Guides",
  Offers: "Offers",
  "Social Groups": "Social Groups",
  " ": " ",
  "404 Not Found": "404 Not Found!",
  "Your email": "Your email",
  "Remember me": "Remember me",
  Password: "Password",
  "Please provide a correct x": "Please provide a correct {{item}}",
  email: "email",
  password: "password",
  "Your x": "Your {{item}}",
  Physiotherapist: "Physiotherapist",
  Naprapath: "Naprapath",
  Chiropractor: "Chiropractor",
  Nutritionist: "Nutritionist",
  "Diet counselor": "Diet counselor",
  Dietician: "Dietician",
  Physio: "Physio",
  "Yoga instructor": "Yoga instructor",
  "Mindfulness Coach": "Mindfulness Coach",
  Coach: "Coach",
  "Self taught inspirer": "Self taught inspirer",
  Other: "Other",
  Email: "Email",
  "x cannot be empty": "{{item}} cannot be empty",
  "Confirm password": "Confirm password",
  "user agreement": "user agreement",
  "Professional title": "Professional title",
  "Company name": "Company name",
  "First name": "First name",
  "Last name": "Last name",
  Homepage: "Homepage",
  "Password does not match": "Password does not match",
  "Password must be minimum 8 characters long":
    "Password must be minimum 8 characters long",
  Success: "Success",
  "Privacy Policy": "Privacy Policy",
  "Use your campaign pages to bring in new customers":
    "Use your campaign pages to bring in new customers. <0>Read more</0>",
  "x comments": "{{item}} Comments",
  "x boosts": "{{item}} Boosts",
  trained: "trained",
  Kcal: "Kcal",
  Length: "Length",
  Distance: "Distance",
  Publish: "Publish",
  "New greeting": "New Instruction",
  "The message is shown to everyone who bought the product":
    "The message is shown to everyone who bought the product",
  "Add new comment": "Add new comment...",
  "Type your greeting": "Type your instruction...",
  "No greetings published yet": "No instructions published yet",
  "No activities published yet": "No activities published yet",
  "No activities recorded yet": "No activities recorded yet",
  "Type the x": "Type the {{item}}...",
  "x month": "{{item}} month",
  month: "month",
  "Nothing added yet": "Nothing added yet",
  "Add new": "Add new",
  Edit: "Edit",
  Chat: "Chat",
  Features: "Features",
  "Target Areas": "Target Areas",
  "Areas of Interest": "Areas of Interest",
  Description: "Description",
  "Cover image": "Cover image",
  "Diet plan": "Diet plan",
  Files: "Files",
  Preview: "Preview",
  Extras: "Extras",
  "Social group": "Social group",
  "Preview page": "Preview page",
  "What's included": "What's included",
  "Contract length": "Contract length",
  "Filter settings": "Filter settings",
  "Prices and contract lengths": "Prices and contract lengths",
  Participants: "Participants",
  "Product information": "Product information",
  "Package settings": "Package settings",
  "Upload file": "Upload file",
  Cancel: "Cancel",
  "Save changes": "Save changes",
  Title: "Title",
  Prices: "Prices",
  "Ex 695 SEK": "Ex 695 SEK",
  "per month": "per month",
  Total: "Total",
  "Add New": "Add New",
  Heading: "Heading",
  "Short description": "Short description",
  "Upload image": "Upload image",
  name: "name",
  "Name of product": "Name of product",
  "participant count": "participant count",
  "Maximum number of participants": "Maximum number of participants",
  "Extra settings": "Extra settings",
  "Standard content": "Standard content",
  "Chat with the coach": "Chat with the coach",
  "Files (PDF)": "Files (PDF)",
  Stream: "Stream",
  Clients: "Clients",
  Reminder: "Reminder",
  Time: "Time",
  "Are you sure you want to x the y":
    "Are you sure you want to {{itemX}} the {{itemY}}? This action cannot be undone.",
  "Deleting the x": "Deleting the {{item}}",
  "Canceling the x": "Canceling the {{item}}",
  "Last x activities": "Last {{item}} activites",
  "Compliance x days": "Compliance {{item}} days",
  "Day x of y": "Day {{itemX}} of {{itemY}}",
  "z from x to y": "{{itemZ}}. From: {{itemX}} to: {{itemY}}",
  "Edit period": "Edit period",
  "Cancel client": "Cancel client",
  "Calendar & Plans": "Calendar & Plans",
  Addons: "Addons",
  "Client library": "Client library",
  "Schedule the customer's training so they can follow it in their calendar day by day":
    "Schedule the customer's training so they can follow it in their calendar day by day",
  "Nothing planned yet": "Nothing planned yet",
  "Add plan": "Add plan",
  Missed: "Missed",
  Performed: "Performed",
  "Other workouts": "Other workouts",
  "Client workouts outside your plan": "Client workouts outside your plan",
  "The materials that customer has access in the app":
    "The materials that customer has access in the app",
  Details: "Details",
  Workouts: "Workouts",
  Others: "Others",
  "Weight and Measurements": "Weight and Measurements",
  "Minutes trained": "Minutes trained",
  "Last 6 week": "Last 6 week",
  "average/week": "average/week",
  "Weight lifted": "Weight lifted",
  "Last 7 days": "Last 7 days",
  "Last unread message": "Last unread message",
  "One or more notes are due": "One or more notes are due",
  "View more": "View more",
  "Note from ": "Note from ",
  "No reminders set": "No reminders set",
  Note: "Note",
  "Edit note": "Edit note",
  "Note text": "Note text",
  "Reminder date": "Reminder date",
  Closed: "Closed",
  "Follow up text": "Follow up text",
  note: "note",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  "x templates": "{{item}} templates",
  Ended: "Ended",
  client: "client",
  Passive: "Passive",
  "View other coaching periods": "View other coaching periods",
  "Extend coaching": "Extend coaching",
  "Add to journal": "Add to journal",
  Close: "Close",
  Training: "Training",
  "Use template": "Use template",
  "Add a predefined template": "Add a predefined template",
  "Search templates...": "Search templates...",
  "Go back": "Go back",
  "Customer templates": "Customer templates",
  "Coaching periods": "Coaching periods",
  "Edit coaching period": "Edit coaching period",
  "End date": "End date",
  "Or select the number of months, weeks or days you want to add to the end date.":
    "Or select the number of months, weeks or days you want to add to the end date.",

  "Add Weight Lifting workout to customer":
    "Add Weight Lifting workout to customer",
  "Workout title": "Workout title",
  "Time x": "Time {{item}}",
  "Select date": "Select date",
  Comments: "Comments",
  "Type your comments here...": "Type your comments here...",
  "Intro clip": "Intro clip",
  "A short video to be shown before starting the workout":
    "A short video to be shown before starting the workout",
  "Video workout": "Video workout",
  "Paste a youtube link to the video that is the full workout":
    "Paste a youtube link to the video that is the full workout",
  "Show advanced options": "Show advanced options",
  Save: "Save",
  "Change icon": "Change icon",
  "All icons": "All icons",
  "(min)": "(min)",
  "Distance x": "Distance {{item}}",
  "Pulse x": "Pulse {{item}}",
  "Calories x": "Calories {{item}}",
  "Score x": "Score {{item}}",
  "Image gallery": "Image gallery",
  "Uploaded images": "Uploaded images",
  "Click on the video that you wish to use":
    "Click on the video that you wish to use",
  "Change image": "Change image",
  "Change header image": "Change header image",
  "Upload an image or select from the gallery":
    "Upload an image or select from the gallery",
  "Upload an image": "Upload an image",
  "Video library": "Video library",
  "You can send a limited number of free offers where the customer can join your package without paying. Your channel has x free invites left.":
    "You can send a limited number of free offers where the customer can join your package without paying. Your channel has {{item}} free invites left.",
  "Create a new plan": "Create a new plan",
  "Start by entering a name, comment and the number of days you want in your routine. For example a week routine has 7 days and can be repeated any number of times when you add it to your diary.":
    "Start by entering a name, comment and the number of days you want in your routine. For example a week routine has 7 days and can be repeated any number of times when you add it to your diary.",
  "Create plan": "Create plan",
  "Add new Cardio Workout to customer": "Add new Cardio Workout to customer",
  "(min.sec)": "(min.sec)",
  "(km)": "(km)",
  "Select an activity": "Select an activity",
  "Here you can add video links and sound clips to the notation.":
    "Here you can add video links and sound clips to the notation.",
  "Upload audio": "Upload audio",
  "Add media": "Add media",
  "Add new Cardio Gym Workout to customer":
    "Add new Cardio Gym Workout to customer",
  "Add activities to your workout": "Add activities to your workout",
  "Add Gym Class workout to customer": "Add Gym Class workout to customer",
  "Add new Sport workout to customer": "Add new Sport workout to customer",
  "Add customer text notation": "Add customer text notation",
  Image: "Image",
  notation: "notation",
  "Here you can upload image to be added to your x":
    "Here you can upload image to be added to your x",
  "Current image": "Current image",
  "Add video clip": "Add video clip",
  "Add customer task": "Add customer task",
  "(points)": "(points)",
  "Points given to a user participating in a TeamBoost company challenge":
    "Points given to a user participating in a TeamBoost company challenge",
  "Add customer test notation": "Add customer test notation",
  "Receive a notification when the customer have answered the test":
    "Receive a notification when the customer have answered the test",
  "Copy plan from library": "Copy plan from library",
  "Add a plan to a clients calendar here. The client will get it's own copy that you can edit independently.":
    "Add a plan to a clients calendar here. The client will get it's own copy that you can edit independently.",
  "Search plans...": "Search plans...",
  "Title of the plan...": "Title of the plan...",
  "Here you can extend the coaching of a customer by sending a new offer.":
    "Here you can extend the coaching of a customer by sending a new offer.",
  "Get paid through Twiik": "Get paid through Twiik",
  "Set the monthly cost for your service that the client should pay.":
    "Set the monthly cost for your service that the client should pay.",
  "The customer pays in another way": "The customer pays in another way",
  "Private workout": "Private workout",
  Day: "Day",
  "Activate as addon": "Activate as addon",
  customer: "customer",
  your: "your",
  plan: "plan",
  "Edit plan scheme": "Edit plan scheme",
  "Edit plan info": "Edit plan info",
  "Here you can add workouts and nutrition to this scheme":
    "Here you can add workouts and nutrition to this scheme",
  "Edit plan information": "Edit plan information",
  Achievements: "Achievements",
  "Create achievement": "Create achievement",
  "Create and edit acheivements that the users gets when the workout is performed.":
    "Create and edit acheivements that the users gets when the workout is performed.",
  achievement: "achievement",
  "Video based": "Video based",
  "Workout based": "Workout based",
  "Block x": "Block {{item}}",
  Intensity: "Intensity",
  "Borg scale": "Borg scale",
  "Select estimated effort": "Select estimated effort",
  "Select exercise type": "Select exercise type",
  "Exercise based": "Exercise based",
  "The workout consists of text instructions and a long video or a sound clip.":
    "The workout consists of text instructions and a long video or a sound clip.",
  Create: "Create",
  "Media based": "Media based",
  "Build your workout with exercises, timers, reps and weight.":
    "Build your workout with exercises, timers, reps and weight.",
  "Add exercises": "Add exercises",
  "Add another block": "Add another block",
  'Block name (ie "Warmup", "Part 1" etc)':
    'Block name (ie "Warmup", "Part 1" etc)',
  "No exercises added yet": "No exercises added yet",
  "Add exercise to block": "Add exercise to block",
  "Add exercise": "Add exercise",
  "Filters / tags": "Filters / tags",
  "Muscle groups": "Muscle groups",
  Categories: "Categories",
  "Your tags": "Your tags",
  Tags: "Tags",
  "Add set": "Add set",
  "Set x": "Set {{item}}",
  kg: "kg",
  "Comments...": "Comments...",
  "sec rest": "sec rest",
  "Select intensity": "Select intensity",
  "Display name": "Display name",
  "Library name": "Library name",
  "Upload audio file": "Upload audio file",
  "Media resources": "Media resources",
  "Select a file": "Select a file",
  "Leave blank for automatic calculation":
    "Leave blank for automatic calculation",
  Pulse: "Pulse",
  "(max)": "(max)",
  Products: "Products",
  "email of the customer": "email of the customer",
  Language: "Language",
  language: "language",
  Send: "Send",
  invitation: "invitation",
  "Show all": "Show all",
  "table.Leads.emptyTitle": "table.Leads.emptyTitle",
  "table.Leads.emptyDescription": "table.Leads.emptyDescription",
  "table.Leads.emptyButton": "table.Leads.emptyButton",
  "Client awaits your answer": "Client awaits your answer",
  "The client is waiting for you to accept or deny the application":
    "The client is waiting for you to accept or deny the application",
  "Deny lead": "Deny lead",
  Client: "Client",
  "Chat with client": "Chat with client",
  "Replies to the needs analysis": "Replies to the needs analysis",
  OK: "OK",
  Package: "Package",
  Period: "Period",
  Social: "Social",
  "Social groups": "Social groups",
  "Create a social group": "Create a social group",
  description: "description",
  "Group type": "Group type",
  "Group status": "Group status",
  Group: "Group",
  Members: "Members",
  App: "App",
  group: "group",
  Unpayed: "Unpayed",
  Payed: "Payed",
  "table.Offers.emptyTitle": "table.Offers.emptyTitle",
  "table.Offers.emptyDescription": "table.Offers.emptyDescription",
  "table.Offers.emptyButton": "table.Offers.emptyButton",
  "Binding time": "Binding time",
  "Not sent yet": "Not sent yet",
  "Price plan": "Price plan",
  Logout: "Logout",
  "Only fill in a password if you wish to change your current password":
    "Only fill in a password if you wish to change your current password",
  Website: "Website",
  "Drop your image here, or click to select one":
    "Drop your image here, or click to select one",
  "-NO DATA-": "-NO DATA-",
  "No active products to display!": "No active products to display!",
  "Length (x)": "Length ({{item}})",
  "Distance (x)": "Distance ({{item}})",
  "No active x to display": "No active {{item}} to display!",
  "Product type": "Product type",
  ptonline: "PT-Package",
  Instructions: "Instructions",
  user: "user",
  "No reminder": "No reminder",
  "Password required": "Password required",
  "Successfully logged in": "Successfully logged in",
  "Email required": "Email required",
  course: "Bootcamp",
  "Add user": "Add user",
  "Create a new user": "Create a new user",
  "Purchase date": "Purchase date",
  Payment: "Payment",
  "No payment": "No payment",
  "Instruction message cannot be empty": "Instruction message cannot be empty",
  "No follow up message added yet!": "No follow up message added yet!",
  "Reminder date:": "Reminder date:",
  Preferences: "Preferences",
  "Order Date": "Order Date",
  Username: "Username",
  username: "username",
  "Enter only if you want to change the password":
    "Enter only if you want to change the password",
  "Email address": "Email address",
  "phone number": "phone number",
  "first name": "first name",
  "last name": "last name",
  gender: "gender",
  height: "height",
  Timezone: "Timezone",
  timezone: "timezone",
  Birthdate: "Birthdate",
  Country: "Country",
  country: "country",
  City: "City",
  city: "city",
  product: "product",
  unspecified: "unspecified",
  "Group x required": "Group {{item}} required",
  Access: "Access",
  "Search...": "Search...",
  "group type": "group type",
  "group status": "group status",
  "Group Members": "Group Members",
  "Add member": "Add member",
  Location: "Location",
  Administrator: "Administrator",
  "Add new group member": "Add new group member",
  "Group stream": "Group stream",
  "List of events logged by your customers in this group":
    "List of events logged by your customers in this group",
  "View the events logged by all of your customers":
    "View the events logged by all of your customers",
  "Product stream": "Product stream",
  "List of events logged by your customers in this product":
    "List of events logged by your customers in this product",

  "Added by coach": "Added by coach",

  "Group posts": "Group posts",
  "Other posts": "Other posts",
  wrote: "wrote",
  "Group post": "Group post",
  "create note": "Create note",
  "update note": "Update note",
  "Add group message": "Add group message",
  Content: "Content",
  "Enter the message content...": "Enter the message content...",
  "Select image": "Select image",
  "Add image to your post": "Add image to your post",
  Post: "Post",

  "Something went wrong": "Something went wrong!",
};

const campaignPageTokens = {
  "Name required": "Name required",
  "Name should contain at least 5 characters":
    "Name should contain at least 5 characters",
  "Template required": "Template required",
  "Archived campaigns": "Archived campaigns",
  "Activate campaign": "Activate campaign",
  "View campaign": "View campaign",
  "Copy campaign link to clipboard": "Copy campaign link to clipboard",
  "Pause campaign": "Pause campaign",
  "Published - live": "Published - live",
  "Close campaign": "Close campaign",
  Draft: "Draft",
  "Send to drafts": "Send to drafts",

  delete: "delete",
  campaign: "campaign",
  "Deleting...": "Deleting...",
  "Successfully deleted": "Successfully deleted",

  "Current company:": "Current company:",
  "Edit campaign": "Edit campaign",
  "Copy campaign": "Copy campaign",
  "Delete campaign": "Delete campaign",
  "Archive campaign": "Archive campaign",
  "Campaign description (optional)": "Campaign description (optional)",
  "Description for social media previews":
    "Description for social media previews",
  "x - Twiik Campaign manager": "{{item}}  - Twiik Campaign manager",
  "Published - Live in x days": "Published - Live in {{item}} days",
  "Twiik Campaign manager": "Twiik Campaign manager",
  "Create and manage efficient growth campaigns":
    "Create and manage efficient growth campaigns",
  Expired: "Expired",

  "column 1": "column 1",
  "Enter the column 1": "Enter the column 1",
  "column 2": "column 2",
  "Enter the column 2": "Enter the column 2",
  "column 3": "column 3",
  "Enter the column 3": "Enter the column 3",
  "image content": "image content",
  "Enter the image content": "Enter the image content",
  "Creating...": "Creating...",
  "Successfully created": "Successfully created",
  "There is no campaign to display": "There is no campaign to display",
  "Main menu": "Main menu",

  "Successfully saved": "Successfully saved",
  "Successfully published": "Successfully published",
  "Copy link": "Copy link",
  Unpublish: "Unpublish",
  "Successfully unpublished": "Successfully unpublished",

  "Email address (to collect form data)":
    "Email address (to collect form data)",
  "Enter the email...": "Enter the email...",

  Views: "Views",
  Conversions: "Conversions",
  Rate: "Rate",

  Campaigns: "Campaigns",
  Drafts: "Drafts",
  "Active campaigns": "Active campaigns",
  "Closed campaigns": "Closed campaigns",
  Published: "Published",
  "Add new campaign": "Add new campaign",
  "Campaign name": "Campaign name",
  "Type the name of your campaign...": "Type the name of your campaign...",
  "Campaign template": "Campaign template",
  Select: "Select",
  "Live in 3 days": "Live in 3 days",
  Selected: "Selected",

  "Call to action": "Call to action",
  "Page not published": "Page not published",
  "Save draft": "Save draft",

  "Background image": "Background image",
  "background image": "background image",
  "Enter the background image": "Enter the background image",
  headline: "headline",
  "Enter the headline": "Enter the headline",
  "Enter the description": "Enter the description",
  "call to action": "call to action",
  "Enter the call to action": "Enter the call to action",
  none: "none",
  link: "link",
  dialog: "dialog",
  "Action URL": "Action URL",
  "Enter the link": "Enter the link",

  "Select the background image": "Select the background image",
  "Action label": "Action label",
  "Enter the label": "Enter the label",

  "checklist heading": "checklist heading",
  "Enter the checklist heading": "Enter the checklist heading",
  checklist: "checklist",
  "Enter the checklist": "Enter the checklist",
  "Checklist items": "Checklist items",
  "Enter checklist item": "Enter checklist item",
  image: "image",
  "Enter the image": "Enter the image",
  "Select the image": "Select the image",

  content: "content",
  "Enter the content": "Enter the content",
  "text content": "text content",
  "Enter the text content": "Enter the text content",

  Styles: "Styles",
  "Back to main menu": "Back to main menu",
  primary: "primary",
  secondary: "secondary",

  logo: "logo",
  "Enter the logo": "Enter the logo",
  "Select the logo": "Select the logo",
  "home address": "home address",
  "Enter the home address": "Enter the home address",
  "x border radius": "{{item}} border radius",
  "x color": "{{item}} color",

  "Choose a dialog to edit": "Choose a dialog to edit",
  "Selected dialog": "Selected dialog",

  "Select an input type": "Select an input type",
  "Input type": "Input type",
  "Input label": "Input label",
  Required: "Required",

  Dialogs: "Dialogs",

  "New dialog": "New dialog",
  "Dialog meta": "Dialog meta",
  "Form name (not visible to users)": "Form name (not visible to users)",
  "Form heading": "Form heading",
  "Form submit text": "Form submit text",
  "Delete dialog": "Delete dialog",
  "Add input": "Add input",
};

const newOfferModal = {
  "Create a new offer": "Create a new offer",
  "Fill in the customer's contact details below":
    "Fill in the customer's contact details below",
  Next: "Next",
  "Phone number": "Phone number",
  "Needs analysis": "Needs analysis",
  "Here you decide which needs analysis is needed and who will complete it. Read more about what our needs analysis looks like and how it is used here.":
    "Here you decide which needs analysis is needed and who will complete it. Read more about what our needs analysis looks like and how it is used here.",
  "You want to fill out the needs analysis":
    "You want to fill out the needs analysis",
  "No analysis": "No analysis",
  "The client should fill out the needs analysis":
    "The client should fill out the needs analysis",
  "Select needs analysis": "Select needs analysis",
  "Select period & price": "Select period & price",
  "Here you choose how long the coaching will last and what the customer will pay.":
    "Here you choose how long the coaching will last and what the customer will pay.",
  "Start date": "Start date",
  "Coaching period": "Coaching period",
  "Payment settings": "Payment settings",
  "Overview offer": "Overview offer",
  "Here you choose how long the coaching will last and what the customer will pay":
    "Here you choose how long the coaching will last and what the customer will pay",
  "Send offer": "Send offer",
  Customer: "Customer",
  "Selected PT-Package": "Selected PT-Package",
  "PT Package title": "PT Package title",
  "Total: ": "Total: ",
  "Needs analysis: ": "Needs analysis: ",
  "Offer ready to be sent": "Offer ready to be sent",
  Invitations: "Invitations",
  "Show x": "Show {{item}}",
  "Select x": "Select {{item}}",
  "Filter by x": "Filter by {{item}}",
  "Group by x": "Group by {{item}}",
  Gender: "Gender",
  Age: "Age",
  Height: "Height",
  Statistics: "Statistics",
  "Files/Meal plans": "Files/Meal plans",
  "This customer performed tests": "This customer performed tests",
  "Summary and average": "Summary and average",
  activities: "activities",
  activity: "activity",
  date: "date",
  duration: "duration",
  distance: "distance",
  Filter: "Filter",
  "Upload files": "Upload files",
  "title of the file": "title of the file",
  Type: "Type",
  type: "type",
  "#": "#",
  Category: "Category",
  Activity: "Activity",
  Pass: "Pass",
  Date: "Date",
  Speed: "Speed",
  Pace: "Pace",
  "Average Heart Rate": "Average Heart Rate",
  "Maximum Heart Rate": "Maximum Heart Rate",
  Calories: "Calories",
  View: "View",
  Download: "Download",
  file: "file",
  "x days": "{{item}} days",
  "x completed": "{{item}} completed",
  "Filter by the date": "Filter by the date",
  Reset: "Reset",
  "username or email": "username or email",
  "Add from library": "Add from library",
  "Create new": "Create new",
  "New period": "New period",
  Show: "Show",
  Week: "Week",
  "No conversation selected": "No conversation selected",
  Remove: "Remove",
  "Mark as unread": "Mark as unread",
  "Load more": "Load more",
  "Type here...": "Type here...",
  "Enter the caption...": "Enter the caption...",
  "Preview image": "Preview image",
  "Successful!": "Successful!",
};

const table = {
  Bootcamps: {
    emptyTitle: "Get started with Bootcamps",
    emptyDescription:
      'Skapa PT-paket för att komma igång med PT-online. Varje PT-paket får en egen säljsida via vilken tänkta klienter ansöker. Ansökningarna hittar du under rubriken "Ansökningar".',
    emptyButton: "Create a new Bootcamp",
  },
  "PT-Online": {
    emptyTitle: "Get started with PT-Online",
    emptyDescription:
      'Skapa PT-paket för att komma igång med PT-online. Varje PT-paket får en egen säljsida via vilken tänkta klienter ansöker. Ansökningarna hittar du under rubriken "Ansökningar".',
    emptyButton: "Create a new PT-Package",
  },
  Users: {
    emptyTitle: "Invite your first customer",
    emptyDescription:
      "Invite your first customer manually or set up a lead generator.",
    emptyButton: "Invite customer",
  },
  Invitations: {
    emptyTitle: "No invitations has been found",
    emptyDescription: "Try inviting a new customer",
    emptyButton: "Invite customer",
  },
  Clients: {
    emptyTitle: "No customers has been found",
    emptyDescription: "Try inviting a new customer",
    emptyButton: "Invite customer",
  },
  Products: {
    emptyTitle: "Nothing to show",
    emptyDescription: "Customer has not ordered any product yet",
  },
  Notes: {
    emptyTitle: "Nothing to show",
    emptyDescription: "No notes has been found",
  },
  customer_notes: {
    emptyTitle: "Nothing to show",
    emptyDescription: "Customer has not been given any notes",
  },
};

const hero = {
  title: {
    dashboard: "Welcome",
    coaching: "Coaching",
    guides: "Guides",
    stream: "Customer stream",
    notifications: "Notifications",
    messages: "Messages",
    invitations: "Invitations",
    clients: "Customers",
    notes: "Notes",
    profile: "My profile",
    social: "Social groups",
    leads: "New Leads",
    offers: "Offers",
    campaign: "Campaigns",
  },
  description: {
    dashboard:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet feugiat neque. Aenean eu volutpat urna, at congue libero.",
    coaching:
      "Here you set up PT packages and create Bootcamps that you easily sell via ready-made campaign pages.",
    guides:
      "Learn how to get started, use coaching interface and get answers to frequently asked questions.",
    stream:
      "Here you can see a combined flow of events from all your customers.",
    notifications: "All recent notifications will be shown here",
    invitations: "Here you can invite customers to your services.",
    clients:
      "In this list you will find all users who are connected to you or one of your services. Click on a service below a customer to get straight to the customer page in that service.",
    notes: "Notes with reminders that need your attention",
    profile:
      "Change your personal information and settings for your Coach account.",
    social: "Here you can create a new group or edit your previous groups.",
    leads: "These are your new leads that you need to reply to",
    offers: "Manage offers sent to your clients",
    campaign:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet feugiat neque. Aenean eu volutpat urna, at congue libero.",
  },
};

const sidebar = {
  coaching: "Coaching",
  library: "Library",
  channel: "Channel",
  company: "Company",
  admin: "Admin",
};

const title = {
  dashboard: {
    admin: "Admin",
    todo: "To-Do list",
    finances: "Finances",
    news: "Latest news",
  },
  coaching: {
    intro: "PT-Online & Bootcamps",
    intro_desc:
      "Create PT packages to get started with PT online. In each PT package, you can invite customers through our quote function or by easily creating your own sales page for the PT package where you can get leads by allowing interested parties to register an interest.",
    pt: "PT-Packages",
    pt_desc:
      "En gruppcoachning som går mellan fasta datum och ger samma upplägg till alla deltagare. Ingen individuell anpassning eller analys.",
    bootcamps: "Bootcamps",
    bootcamps_desc:
      "En gruppcoachning som går mellan fasta datum och ger samma upplägg till alla deltagare. Ingen individuell anpassning eller analys.",
  },
  product: {
    activities: "Recent activities of the users",
    activities_desc: "Latest exercises of the customer listed below",
    greetings: "Instructions",
    greetings_desc:
      "An instruction you write here will be shown to customers when they open the app. All customers of the service receive this message regardless of where they are in the program. In program-based services, you can add instructions to the days in the program so that they appear when users reach a given day in the program.",
  },
  personal: {
    overview: "Latest logged items",
    overview_desc:
      "This list contains the latest activities from this customer. All activities from this customer is shown here even if it is from another service or program. Use the side menu to navigate this customers info",
    tests: "Tests",
    files: "Files/Meal plans",
    files_desc:
      "Add PDF files you want your client to access. For example a meal plan. The client will find it under 'My things' on the app startpage.",
    calendar: "Calendar",
    calendar_desc:
      "Below you see the customer's workout calendar. The workouts entered by you are marked with three different colors. Green is workouts marked as performed by the cusomter. Red is workouts marked as missed or not performed by the customer. Yellow is awaiting to be marked by the customer.",
    plans: "Plans",
    plans_desc:
      "Create or copy posts to your customer to activate longer plans that can be repeated easily",
  },
};

const tables = {
  title: {
    "PT-Online": "PT-Packages",
    Bootcamps: "Bootcamps",
    Users: "Users",
    Invitations: "",
    Clients: "",
    Notes: "",
    "Social group": "",
    Products: "",
    note: "",
    Leads: "",
    Offers: "Add new client",
    file: "Files/Meal plans",
    Statistics: "",
    plan: "Plans",
    "file addons": "Files",
    workout: "Workouts",
    test: "Tests",
    task: "Tasks",
    knowledge: "Knowledge",
    customer_notes: "Notes",
    social_group_members: "",
  },
  description: {
    "PT-Online":
      "Create PT packages to get started with PT online. Each PT package has its own sales page via which prospective clients apply. You will find the applications under the heading 'Applications'.",
    Bootcamps:
      "A group coaching that goes between fixed dates and gives the same approach to all participants. No individual adaptation or analysis",
    Users: "Here you see a list of the customers in your coaching",
    Invitations: "",
    Clients: "",
    Notes: "",
    customer_notes:
      "Write notes on your customer to help you remember things (only visible to you)",
    "Social group": "",
    Products: "Services the customer is connected to",
    note: "Write notes on your customer to help you remember things (only visible to you)",
    Leads: "",
    Offers: "",
    file: "Add PDF files you want your client to access. For example a meal plan. The client will find it under 'My things' on the app startpage.",
    Statistics: "",
    plan: "Lorem ipsum dolor si amet",
    "file addons": "Lorem ipsum dolor si amet",
    workout: "Lorem ipsum dolor si amet",
    test: "Lorem ipsum dolor si amet",
    task: "Lorem ipsum dolor si amet",
    knowledge: "Lorem ipsum dolor si amet",
    social_group_members: "",
  },
  action: {
    default: "Add new",
    "PT-Online": "Create new package",
    Bootcamps: "Create new bootcamp",
    Users: "Invite a user",
    Invitations: "Invite a user",
    "Social group": "Create a new group",
    note: "New Note",
    Offers: "Create new offer",
    file: "Upload new files",
    plan: "Add",
    "file addons": "Add",
    workout: "Add",
    test: "Add",
    task: "Add",
    knowledge: "Add",
  },
};

const onboarding = {
  title: {
    login: "Login",
    register: {
      s1: "Create your account for free",
      s2: "Create your account for free",
      s3: "Tell us a bit more about yourself",
      s4: "Your own homepage",
      s5: "You're done!",
    },
    reset: "Reset",
  },
  subtitle: {
    login: "Log in to your existing coach account",
    register: {
      s1: "Do as thousands of other gyms and personal trainers",
      s2: "Do as thousands of other gyms and personal trainers",
      s3: "Before we get started, we need to know a little more about you",
      s4: "Many of our services you create in Twiik Coach can be sold and presented on campaign pages we create for you. You and your customers will find the page at the following address:",
      s5: "Your account is now ready and you can get started with everything that Twiik Coach has to offer.",
    },
    reset:
      "Enter your email address and your username in the field below and click on Send to get a new password sent to you automatically. NB! Please add twiik.me as a trusted sender to your email white list. Otherwise the password mail might get stuck in your spam filter",
  },
  link: {
    login: "Forgot your password? Request a new one",
    register: "Do you already have an account? Sign in",
  },
  button: {
    login: "Login",
    register: "Get going",
    reset: "Reset",
  },
  success: {
    h0: "We're here for you",
    h1: "Help and guides",
    h2: "Webinars",
    p0: "You can always contact us for help getting started. support@twiik.me",
    p1: "On most overview pages you will find links to good guides and get-going tutrorials.",
    p2: "There are free webinars you can listen to for help and inspiration on how to work in the tool.",
  },
  misc: {
    agreement:
      "To create an account, you must accept Twiiks <0>user agreement</0>",
    home_helper: "You can change your address later in your settings",
    reset_done:
      "If you don't receive your password within 30 minutes, contact us at appsupport@twiik.me",
    welcome_title: "Welcome to a new digital journey",
    welcome_body:
      "With a coach account at Twiik, a whole world of possibilities opens up. Whether you currently work directly with individuals, sell digital concepts, run a gym or work with company health, we have something for you.",
  },
};

const notification = {
  no_bank_details:
    "You have not filled in your details, fill in these to get your payment..",
};

const en = {
  translation: {
    ...generic,
    ...newOfferModal,
    ...campaignPageTokens,
    hero,
    title,
    notification,
    table,
    onboarding,
    tables,
    sidebar,
  },
};

export default en;
