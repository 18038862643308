import { Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useChatConversationSubscription } from "../../../api/chatDetails";
import { useGetUnreads } from "../../../api/user/getUnreads";
import { useGetUser } from "../../../api/user/getUser";

export const withAuth = (Component) => {
  return function Wrapper(props) {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth, shallowEqual);
    const { data } = useGetUnreads();
    const { data: userData } = useGetUser();

    const isSubscribed = useChatConversationSubscription();

    useEffect(() => {
      if (userData) {
        dispatch({
          type: "SET_USER",
          payload: userData,
        });
      }
    }, [dispatch, userData]);

    useEffect(() => {
      if (data) {
        dispatch({
          type: "SET_UNREADS",
          payload: data,
        });
      }
    }, [data]);

    if (!auth.logged) {
      return <Navigate replace to={"/login"} />;
    }

    return <Component {...props} />;
  };
};
