import { Button } from "@carbon/react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./notfound.module.scss";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{t("x - Twiik Coach", { item: t("404 Not Found") })}</title>
      </Helmet>

      <div className={"tc-card " + styles.box}>
        <h1>{t("Page Not Found")}</h1>
        <p>{t("The page you are looking for could not be found")}</p>
        <Button
          kind="secondary"
          onClick={() => navigate("/", { replace: true })}
        >
          {t("Go to Homepage")}
        </Button>
      </div>
    </>
  );
};

export default NotFound;
