import { default as CarbonLoading } from "@carbon/react/lib/components/Loading/Loading";
import styles from "./loading.module.scss";

const Loading = () => {
  return (
    <div className={styles.loading}>
      <CarbonLoading withOverlay={false} small />
    </div>
  );
};
export default Loading;
