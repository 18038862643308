import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, Navigate, Outlet } from "react-router-dom";
import backgroundImage from "../../../assets/images/auth.jpeg";
import twiik_coach_logo from "../../../assets/images/twiik_logo.png";
import Loading from "../../Loading";
import styles from "./unregistered.module.scss";

export default function Unregistered({ notFound = false }) {
  const auth = useSelector((state) => state.auth, shallowEqual);

  if (auth.logged && !notFound) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.background}
        style={{ backgroundImage: "url(" + backgroundImage + ")" }}
      />
      <Link to="/" className={styles.logo}>
        <img src={twiik_coach_logo} className="tc-logo" alt="" />
        <span>Coach</span>
      </Link>
      <React.Suspense fallback={<Loading />}>
        <Outlet />
      </React.Suspense>
    </div>
  );
}
