const defaultState = {
  dismissedGuides: [],
  sidebarLocked: false,
  coachVideoBox: true,
  coachLinksBox: true,
  options: {
    campaignFilterPersonal: "company",
  },
  notifications: {
    unread: 5,
  },
  messages: {
    unread: 13,
  },
  unreadMessages: 0,
  unreadNotifications: 0,
  user: {
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    email: null,
    image: null,
  },
  companies: [],
  selectedCompany: null,
  auth: {
    logged: false,
    accessToken: null,
    refreshToken: null,
    expireTime: null,
    tokenType: null,
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "SIDEBAR_TOGGLE":
      return {
        ...state,
        sidebarLocked: action.payload,
      };
    case "COACH_VIDEO_BOX_DISMISS":
      return {
        ...state,
        coachVideoBox: false,
      };
    case "COACH_LINKS_BOX_DISMISS":
      return {
        ...state,
        coachLinksBox: false,
      };
    case "DISMISS_GUIDE":
      return {
        ...state,
        dismissedGuides: [...state.dismissedGuides, action.payload],
      };
    case "LOGIN":
      return {
        ...state,
        auth: action.payload,
      };
    case "LOGOUT":
      return {
        ...defaultState,
      };
    case "SET_COMPANIES":
      return {
        ...state,
        companies: action.payload,
        selectedCompany: !state.selectedCompany
          ? action.payload[0]?.id
          : state.selectedCompany,
      };
    case "SELECT_COMPANY":
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_UNREAD_NOTIFICATIONS":
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    case "SET_UNREAD_MESSAGES":
      return {
        ...state,
        unreadMessages: action.payload,
      };
    case "SET_CAMPAIGN_FILTER_PERSONAL":
      return {
        ...state,
        options: {
          ...state.options,
          campaignFilterPersonal: action.payload,
        },
      };
    case "SET_UNREADS":
      return {
        ...state,
        unreadMessages: action.payload.unreadMessages,
        unreadNotifications: action.payload.unreadNotifications,
      };
    default:
      return state;
  }
}
