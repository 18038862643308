import { configureStore } from "@reduxjs/toolkit";
//import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import reducer from "./reducer";

const persistConfig = {
  key: "store",
  keyPrefix: "tc:",
  version: 1,
  storage,
};

const middlewares = [
  createStateSyncMiddleware({
    whitelist: ["LOGOUT", "LOGIN"],
  }),
  // logger,
];

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(...middlewares),
});

initMessageListener(store);

export let persistor = persistStore(store);
export default store;
