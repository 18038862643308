import { API } from "aws-amplify";
import gql from "graphql-tag";
import { useEffect, useRef } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import store from "../store";
import doRequest from "./utils/doRequest";

const conversationQuery = gql`
  query ConversationQuery($userId: Int!, $start: Int, $token: String!) {
    conversation(userId: $userId, start: $start, token: $token) {
      status
      messages {
        id
        sender {
          id
          name
          image
        }
        message
        images {
          id
          largeUrl
          mediumUrl
          smallUrl
          width
          height
        }
        isRead
        timeStamp
      }
      messagesCount
      nextStart
      lastId
    }
  }
`;

const getConversation = async ({ queryKey, pageParam = 0 }) => {
  const token = store.getState().auth.accessToken;
  const { userId } = queryKey[1];

  return doRequest(
    {
      query: conversationQuery,
      variables: {
        userId,
        start: pageParam,
        token,
      },
    },
    "data.conversation"
  );
};

export function useChatConversationQuery(userId) {
  return useInfiniteQuery(["conversation", { userId }], getConversation, {
    keepPreviousData: false,
    getNextPageParam: (lastPage) => {
      return lastPage.data.conversation.nextStart ?? false;
    },
  });
}

const sendChatMessageMutation = gql`
  mutation SendChatMessageMutation(
    $senderUserId: Int!
    $recipientUserId: Int!
    $image: String
    $message: String!
    $token: String!
  ) {
    sendChatMessage(
      senderUserId: $senderUserId
      recipientUserId: $recipientUserId
      image: $image
      message: $message
      token: $token
    ) {
      success
      errorMessage
    }
  }
`;

//senderUserId: Int!, recipientUserId: Int!, message: String!, token: String!)

const conversationSubscription = gql`
  subscription ConversationSubscription($recipientUserId: Int) {
    newChatMessageSubscription(recipientUserId: $recipientUserId) {
      message
      recipientUserId
      senderUserId
      timeStamp
    }
  }
`;

export function useChatConversationSubscription() {
  const isSubscribed = useRef(false);
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let subscription;

    if (user?.id) {
      subscription = API.graphql({
        query: conversationSubscription,
        variables: {
          recipientUserId: user?.id,
        },
      }).subscribe({
        next: ({ value }) => {
          const subData = value?.data?.newChatMessageSubscription;

          // Invalidate inbox and the messages from the sender of a new message
          // if it's to slow we should update the cache directly here
          queryClient.invalidateQueries("inbox");
          queryClient.invalidateQueries([
            "conversation",
            { userId: subData.senderUserId },
          ]);
        },
        error: (error) => {
          isSubscribed.current = false;
          console.warn("Subscription errors", error?.error?.errors);
        },
      });

      isSubscribed.current = true;
    }

    return () => {
      isSubscribed.current = false;
      subscription?.unsubscribe();
    };
  });

  return isSubscribed.current;
}

const sendChatMessage = async ({
  senderUserId,
  recipientUserId,
  image,
  message,
}) => {
  const token = store.getState().auth.accessToken;

  const result = await API.graphql({
    query: sendChatMessageMutation,
    variables: {
      senderUserId,
      recipientUserId,
      image,
      message,
      token,
    },
  });

  return result;
};

export function useSendChatMessageMutation(
  senderUserId,
  recipientUserId,
  setMessageStatus,
  setPreviewImage,
  onSuccess
) {
  // console.log(senderUserId, recipientUserId);

  const queryClient = useQueryClient();

  const mutation = useMutation("sendChatMessage", sendChatMessage, {
    onMutate: async (data) => {
      // Set status to pending
      setMessageStatus("pending");

      // Clear image preview
      setPreviewImage(null);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        "conversation",
        { userId: recipientUserId },
      ]);

      // Snapshot the previous value
      const previousConversation = queryClient.getQueryData([
        "conversation",
        { userId: recipientUserId },
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["conversation", { userId: recipientUserId }],
        (old) => {
          return {
            ...old,
            pages: old.pages.map((page, index) => {
              // If it's the first page, put the new message first
              if (index === 0) {
                return {
                  ...page,
                  data: {
                    ...page.data,
                    conversation: {
                      ...page.data.conversation,
                      messages: [
                        {
                          id: Math.round(Math.random() * -1000000),
                          sender: {
                            id: senderUserId,
                            name: "Anonymouse",
                            image: null,
                          },
                          message: data.message,
                          isRead: true,
                          timeStamp: new Date(),
                          isOptimistic: true,
                        },
                        ...page.data.conversation.messages,
                      ],
                    },
                  },
                };
              }

              return page;
            }),
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousConversation };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, data, context) => {
      setMessageStatus("failed");

      alert("Oops!", `Det gick inte att skicka meddelandet ${error}`);
      queryClient.setQueryData(
        ["conversation", { userId: recipientUserId }],
        context.previousConversation
      );
    },
    onSettled: (data, _, __, context) => {
      if (!data?.data?.sendChatMessage?.success) {
        queryClient.setQueryData(
          ["conversation", { userId: recipientUserId }],
          context.previousConversation
        );
        alert(
          "Oops!",
          `Det gick inte att skicka meddelandet ${data?.data?.sendChatMessage?.errorMessage}`
        );
      }

      setMessageStatus("sent");
      // Always refetch after error or success, conversation AND inbox:
      queryClient.invalidateQueries("conversation", {
        userId: recipientUserId,
      });
      queryClient.invalidateQueries("inbox");
      onSuccess();
    },
    // Normally react-query does not retry, let's retry 3 times
    retry: 3,
  });

  return mutation;
}

const markAsUnreadMutation = gql`
  mutation MarkAsUnreadMutation($id: Int!, $token: String!) {
    markAsUnread(id: $id, token: $token) {
      success
      errorMessage
    }
  }
`;

const markAsUnread = async ({ id }) => {
  const token = store.getState().auth.accessToken;

  const result = await API.graphql({
    query: markAsUnreadMutation,
    variables: {
      id,
      token,
    },
  });

  return result;
};

export function useMarkAsUnreadMutation({ onSuccess }) {
  const mutation = useMutation("markAsUnread", markAsUnread, {
    onMutate: async (data) => {
      return data;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, data, context) => {
      alert("Oops!", `Det gick inte att markera chat:en som oläst ${error}`);
    },
    onSettled: (data, error, __, context) => {
      if (!error && data) {
        onSuccess();
      }
    },
    // Normally react-query does not retry, let's retry 3 times
    retry: 3,
  });

  return mutation;
}
