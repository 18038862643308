import React from "react";
import { Route, Routes } from "react-router-dom";
import Registered from "../components/_layouts/Registered";
import Suspense from "../components/_layouts/Suspense";
import Unregistered from "../components/_layouts/Unregistered";
import NotFound from "../components/_pages/NotFound";

const Campaign = React.lazy(() => import("../components/_pages/Campaigns"));
const CampaignEditor = React.lazy(() =>
  import("../components/_pages/Campaigns/Editor")
);
const CampaignSingle = React.lazy(() =>
  import("../components/_pages/Campaigns/Single")
);
const TemplatePreview = React.lazy(() =>
  import("../components/_pages/Campaigns/Single/TemplatePreview")
);
const Profile = React.lazy(() => import("../components/_pages/Profile"));

const Auth = React.lazy(() => import("../components/_pages/Auth"));

export default function Navigation() {
  return (
    <Routes>
      <Route element={<Suspense />}>
        <Route path="/campaign/:id" element={<CampaignSingle />} />
        <Route path="/campaign/templates/:id" element={<TemplatePreview />} />
      </Route>

      <Route path="/campaign/:id/edit" element={<Suspense />}>
        <Route index element={<CampaignEditor />} />
        <Route path=":mode" element={<CampaignEditor />} />
      </Route>

      <Route element={<Registered />}>
        <Route path="/" element={<Campaign />} />
        <Route path={"/profile"} element={<Profile />} />
      </Route>

      <Route element={<Unregistered />}>
        <Route path="/login" element={<Auth type="login" />} />
      </Route>

      <Route element={<Unregistered notFound />}>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
