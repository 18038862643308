import { useQuery } from "react-query";
import gql from "graphql-tag";
import doRequest from "../utils/doRequest";
import store from "../../store";

const getUser = gql`
  query GetUser($userId: Int) {
    getUser(userId: $userId) {
      id
      userName
      firstName
      lastName
      email
      image
      domain
    }
  }
`;

export function useGetUser(userId) {
  let variables = {};

  if (userId) {
    variables = {
      userId,
    };
  }

  return useQuery(
    ["getUser", { userId }],
    async () => {
      return doRequest(
        {
          query: getUser,
          variables,
        },
        "data.getUser"
      );
    },
    {
      retry: 1,
      cacheTime: 60 * 60 * 1000, // let's cache this for an hour
      refetchOnWindowFocus: false,
      onError: (e) => {
        if (
          e.errors.some(
            (err) => err.message === "Request failed with status code 401"
          )
        ) {
          store.dispatch({
            type: "LOGOUT",
          });
        }
      },
    }
  );
}
